import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import PropTypes from 'prop-types'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page403 = React.lazy(() => import('./views/pages/page403/page403'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const isAuthenticated = () => {
  const user = localStorage.getItem('user')

  if (user) {
    return true
  }

  return false
}

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />)}
  />
)
UnauthenticatedRoute.propTypes = {
  component: PropTypes.object.isRequired,
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />)}
  />
)
AuthenticatedRoute.propTypes = {
  component: PropTypes.object.isRequired,
}

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/403" name="Page 403" render={(props) => <Page403 {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            {/* <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} /> */}
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
